import { LeftCircleOutlined } from '@ant-design/icons'
import { Avatar, Col, Row, Space, Tabs } from 'antd'
import { useEffect, useState } from 'react'

import { Button, StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { Labels } from '../../../constants/labels'
import { handleBackButtonClick } from '../../../utilities/general'
import { useEligibilityContext } from '../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../shared/context/PatientEligibilityDetailContext'
import { emptyPatient } from '../shared/form/utilities/eligibilityFormDataHandling'
import PatientInfo from './tabs/PatientEligibilityForm'
import History from './tabs/PatientEligibilityHistory'
import { observer } from '@legendapp/state/react'

const { TabPane } = Tabs

const PatientEligibilityDetail = observer(() => {
  const trellisActiveService = GlobalState.ActiveServices.TRELLIS_CLAIMS.get()
  const dentalIntelActiveService = GlobalState.ActiveServices.DENTAL_INTEL.get()
  const flags = LDFlags$.get()

  const { setShowPatientUpdateFormModal } = useEligibilityContext()
  const {
    activeTab,
    formErrors,
    loading,
    patient,
    patientDisplayName,
    patientStatus,
    setActiveTab,
    setFormErrors,
    setPatient,
    setPatientDisplayName,
    setPatientStatus,
    setEligibilityHistory,
    setShowPatientDetail,
    verifying,
  } = usePatientEligibilityDetailContext()
  const [updateFormDisabled, setUpdateFormDisabled] = useState<boolean>(false)

  const backButtonHandler = () => {
    setFormErrors([])
    setPatient({ ...emptyPatient })
    setShowPatientDetail(false)
    setPatientDisplayName('New Patient')
    setPatientStatus(null)
    setEligibilityHistory([])
    setActiveTab('Patient')
  }

  useEffect(() => {
    handleBackButtonClick(backButtonHandler)
  }, [])

  //Form errors updated
  useEffect(() => {
    if (
      !patient.PatientFirstName ||
      !patient.PatientLastName ||
      !patient.PatientBirthdate ||
      !patient.PatientGender ||
      !patient.ProviderLastName ||
      !patient.IndividualNpi
    )
      setUpdateFormDisabled(true)
    else {
      const validatedFields = [
        'PatientFirstName',
        'PatientLastName',
        'PatientBirthdate',
        'PatientGender',
        'ProviderLastName',
        'IndividualNpi',
      ]
      const filteredErrors = formErrors.filter((error: { name: string[] }) =>
        validatedFields.includes(error.name[0]),
      )

      setUpdateFormDisabled(filteredErrors.length > 0)
    }
  }, [formErrors, patient])

  return (
    <>
      <section>
        <Row
          align='middle'
          className='patient-header-row mb-050'
          justify='space-between'
        >
          <Button
            className='back-to-all'
            icon={<LeftCircleOutlined className='fs-125' />}
            label='Back'
            onClick={backButtonHandler}
            type='text'
          />
          {activeTab === 'Patient' && (
            <Space>
              {!dentalIntelActiveService && trellisActiveService && (
                <Col>
                  <Button
                    disabled={loading || updateFormDisabled}
                    label='Send Update Form'
                    onClick={() => setShowPatientUpdateFormModal(true)}
                    size='large'
                    tooltip={
                      updateFormDisabled
                        ? Labels.updateFormButtonDisabledTooltip
                        : null
                    }
                  />
                </Col>
              )}
              <Col>
                <Button
                  disabled={loading}
                  form='patientEligibilityForm'
                  htmlType='submit'
                  label='Verify'
                  loading={verifying}
                  size='large'
                  type='primary'
                />
              </Col>
            </Space>
          )}
        </Row>
        <Row align='middle'>
          <h1 className='page-content-header__title'>{patientDisplayName}</h1>
          {patientStatus?.status && (
            <section className='status-badge-container'>
              <StatusDisplay
                status={patientStatus?.status}
                toolTip={patientStatus?.toolTip}
                type='badge'
                getStatusOverride={getStatusOverride}
              />
            </section>
          )}
        </Row>
      </section>
      <Tabs
        className='mt-150'
        activeKey={activeTab}
        onChange={(key: string) => setActiveTab(key)}
      >
        <TabPane
          key='Patient'
          tab={
            <>
              <span>Patient Info</span>
              {formErrors.length > 0 && (
                <Avatar
                  size='small'
                  className='form-error-count'
                >
                  {formErrors.length}
                </Avatar>
              )}
            </>
          }
        >
          <PatientInfo />
        </TabPane>
        {((!flags.eligibilityUsevynedentalsync &&
          !flags.operaVynesyncAutomatedeligibility) ||
          patient.PatientId !== '0') && (
          <TabPane
            key='Response History'
            tab='History'
          >
            <History />
          </TabPane>
        )}
      </Tabs>
    </>
  )
})

export default PatientEligibilityDetail
