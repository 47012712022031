import {
  SidebarMenu,
  SidebarMenuAppThemes,
  SidebarMenuProductFeatureRoutes,
  SidebarMenuProducts,
} from '@vynedental/design-system'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import {
  Outlet,
  redirect,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom'

import { LoadingSpinner } from 'ui'
import { LogError, SetSentryUser } from 'utils'

import { GetSsoUrl } from 'trellis:api/authentication/authenticationApi'
import { GlobalContextProvider } from 'trellis:context/GlobalContextProvider'
import GlobalState, { appTheme$, LDFlags$ } from 'trellis:state/globalState'
import { setTheme } from 'trellis:theme/setTheme'
import {
  getQueryParamAndDelete,
  getSearchParamsLowerCase,
} from 'trellis:utilities/general'
import { updateLdContext } from 'trellis:utilities/ldHelper'
import { identifyPendoAuthedUser } from 'trellis:utilities/pendoHelper'
import { SocketContextProvider } from 'trellis:utilities/webSocketClient'

import AlertBanner from './components/AlertBanner/AlertBanner'
import Header from './components/Header/Header'
import PostAuthLayoutProvider from './context/PostAuthLayoutContext'

import './PostAuthLayout.scss'

import { observer } from '@legendapp/state/react'

import { hasRoutePermission, RoleHelper$ } from 'trellis:utilities/roleHelper'

const PostAuthLayout: FC = observer(() => {
  // global state
  const activeServices = GlobalState.ActiveServices.get()
  const activeServicesString = GlobalState.ActiveServicesString.get()
  const authenticated = GlobalState.IsAuthenticated.get()
  const decodedJWT = GlobalState.DecodedJwt.get()
  const flags = LDFlags$.get()
  const practiceInfo = GlobalState.PracticeInfo.get()
  const legalBusinessInfo = GlobalState.LegalBusinessStatus.get()
  const userInfo = GlobalState.UserInfo.get()

  // package hooks
  const { appTheme, canShowLegalBusinessBanner } =
    useLoaderData() as LoaderPostAuthLayoutProps
  const ldClient = useLDClient()
  const [searchParams, setSearchParams] = useSearchParams()
  // local state
  const [isAlertBannerOpen, setIsAlertBannerOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (authenticated) {
      updateLdContext(
        ldClient,
        GlobalState.HasPmsData.peek(),
        GlobalState.ActiveServicesString.peek(),
        GlobalState.UserInfo.globalCustomerID.peek(),
      ).then(() => setIsLoading(false))
    }
  }, [authenticated])

  useEffect(() => {
    if (authenticated) {
      identifyPendoAuthedUser(
        userInfo,
        practiceInfo,
        activeServicesString,
        legalBusinessInfo,
      )

      //set user identifier, needed here in case they refresh the page after auth
      SetSentryUser(
        GlobalState.UserInfo.userName.peek(),
        GlobalState.UserInfo.globalCustomerID.peek(),
        GlobalState.UserInfo.customerUserID.peek(),
        GlobalState.Auth.ActiveServices.peek(),
      )
    }
  }, [authenticated, practiceInfo, userInfo])

  useEffect(() => {
    updateStateFromQueryString()
  }, [])

  const updateStateFromQueryString = () => {
    const lowerSearchParams = getSearchParamsLowerCase(searchParams)

    // only set/update variables if they're in the query so we're not clearing it if they go to a page without it
    const serialId = getQueryParamAndDelete(lowerSearchParams, 'serial')
    //serial id is limited to 50 characters in dru table
    if (serialId && serialId.length <= 50) GlobalState.SerialId.set(serialId)

    // TODO: figure out if we use this for anything, if not remove it from the query string and don't store it
    const isolate = getQueryParamAndDelete(
      lowerSearchParams,
      'isolate',
    )?.toLowerCase()
    if (isolate)
      GlobalState.Claims.ClaimIsolate.set(isolate === 'true' ? true : false)

    setSearchParams(lowerSearchParams)
  }

  const handleSsoUrl = async (
    destinationProduct: SidebarMenuProducts,
    returnPath: string,
  ): Promise<string> => {
    let link = ''

    try {
      await GetSsoUrl({ destinationProduct, returnPath }).then(
        (data) => (link = data.data.ssoUrl),
      )
    } catch (e) {
      LogError(e as Error)
    }

    return link
  }

  const productFeatureRoutes: SidebarMenuProductFeatureRoutes = {
    claims: {
      featurePath: '/Claims',
      marketingPath: '/AdditionalServices/Claims',
    },
    eligibility: {
      featurePath: '/Eligibility',
      marketingPath: '/AdditionalServices/Eligibility',
    },
    carrierList: {
      featurePath: '/CarrierList',
      marketingPath: '/AdditionalServices/CarrierList',
    },
    attachments: {
      featurePath: '/Attachments',
      marketingPath: '/AdditionalServices/Attachments',
    },
    requestPayment: {
      featurePath: '/Payment/PaymentsPortal',
    },
    billingStatements: {
      featurePath: '/Statements',
    },
    campaigns: {
      marketingPath: '/AdditionalServices/Campaigns',
    },
    forms: {
      marketingPath: '/AdditionalServices/Forms',
    },
    onlineScheduling: {
      marketingPath: '/AdditionalServices/OnlineScheduling',
    },
    patientMessaging: {
      marketingPath: '/AdditionalServices/PatientMessaging',
    },
    reminders: {
      marketingPath: '/AdditionalServices/Reminders',
    },
    reviews: {
      marketingPath: '/AdditionalServices/Reviews',
    },
    secureEmail: {
      marketingPath: '/AdditionalServices/Email',
    },
    teamChat: {
      marketingPath: '/AdditionalServices/TeamChat',
    },
  }

  if (flags.chatBot && !GlobalState.ActiveServices.peek().EAGLESOFT) {
    document
      .getElementsByClassName('embeddedServiceHelpButton')[0]
      ?.removeAttribute('style')
  } else {
    document
      .getElementsByClassName('embeddedServiceHelpButton')[0]
      ?.setAttribute('style', 'display:none;')
  }

  return !authenticated || isLoading ? (
    <LoadingSpinner />
  ) : (
    <GlobalContextProvider>
      <SocketContextProvider>
        <PostAuthLayoutProvider>
          <div className={`post-auth-layout ${appTheme}`}>
            <Header />
            <main className='main'>
              <aside className='main__sidebar'>
                {/* {flags.useSharedSidebar ? ( */}
                <SidebarMenu
                  appTheme={appTheme}
                  currentProduct={SidebarMenuProducts.trellis} // TODO: update import once types are added to ui export
                  currentProductHasReactRouter={true}
                  facilityId={decodedJWT?.facility_id}
                  getSsoUrl={handleSsoUrl}
                  isUserSso={userInfo.isSso}
                  practiceManagementSystem={practiceInfo.p_Software}
                  productFeatureRoutes={productFeatureRoutes}
                  productSyncServices={GlobalState.DecodedJwt.active_services
                    .get()
                    .split(',')}
                />
                {/* ) : (
                  <SidebarNavigation
                    activeServices={activeServices}
                    appTheme={appTheme}
                    facilityID={decodedJWT?.facility_id}
                    flags={flags}
                    getSsoUrl={GetSsoUrl}
                    userInfo={userInfo}
                    pmsSoftware={practiceInfo.p_Software}
                  />
                )} */}
              </aside>
              <section
                className={`main__page-content main__page-content${
                  isAlertBannerOpen ? '--with-alert-banner' : '--default'
                }`}
              >
                {canShowLegalBusinessBanner && (
                  <AlertBanner
                    isOpen={isAlertBannerOpen}
                    setIsOpen={setIsAlertBannerOpen}
                  />
                )}
                <Outlet />
              </section>
            </main>
          </div>
        </PostAuthLayoutProvider>
      </SocketContextProvider>
    </GlobalContextProvider>
  )
})

type LoaderPostAuthLayoutProps = {
  appTheme: SidebarMenuAppThemes
  canShowLegalBusinessBanner: boolean
}

export const loaderPostAuthLayout = async ({
  request,
}): Promise<LoaderPostAuthLayoutProps | Response> => {
  const activeServices = GlobalState.ActiveServices.peek()

  let appTheme: SidebarMenuAppThemes = SidebarMenuAppThemes.trellisTheme

  if (activeServices?.DENTAL_INTEL) {
    appTheme = SidebarMenuAppThemes.dentalIntelTheme
  } else if (activeServices?.REMOTE_LITE && !activeServices?.TRELLIS_CLAIMS) {
    appTheme = SidebarMenuAppThemes.rPracticeTheme
  }

  appTheme$.set(appTheme)
  setTheme(appTheme)

  const url = new URL(request.url)
  const canShowLegalBusinessBanner = url.pathname.match(
    /Claims|CarrierList|Attachments/i,
  )
    ? false
    : true

  const hasPermission = hasRoutePermission(url?.pathname)

  if (!hasPermission) {
    //redirect them if they're trying to access a page they don't have permission for
    return redirect(RoleHelper$.defaultRoute.peek())
  } else
    return {
      appTheme,
      canShowLegalBusinessBanner,
    }
}

export default PostAuthLayout
