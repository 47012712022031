import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { endOfDay, format, startOfDay, sub } from 'date-fns'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { LogError } from 'utils'

import { GetCarrierList } from 'trellis:api/attachment/attachmentApi'
import {
  ClaimModel,
  ClaimSettingsResponse,
  ClaimStatusCodes,
} from 'trellis:api/claim/claim-client'
import { GetClaimStatusCodes } from 'trellis:api/claim/claimApi'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { AttachmentCarrier } from '../../../api/attachment/attachment-client'
import { ClaimsApiFactory } from '../../../api/claim/claimsApiFactory'
import { CustomerTypes } from '../../../constants/general'
import api, { ClaimCarrierData } from '../../../utilities/api'
import { ClaimGridState } from '../claimTypes'
import {
  matchClaimStatus,
  matchClaimStatusDescription,
} from '../util/claim-status-mapping'
import { mapStatusCodeDetails } from '../util/status-code-helpers'
import { ClaimDetailsModelExtended } from './claimDetailContext'

/** Claim object (for claim grid row) from the api with extra properties needed for current setup */
export interface ClaimModelExtended extends ClaimModel {
  /** Local property, not part of the submitted claim xml */
  MappedClaimStatusCodes?: ClaimStatusCodes[]
}

//TODO: Update all the any to the correct type
export type ClaimGridContextType = {
  ClaimState$: Observable<{
    isNewClaim: boolean
    showClaimDetail: boolean
    forceShowAttachmentTab: boolean
    currentClaimIDToDelete: string
  }>
  ClaimSettings$: Observable<ClaimSettingsResponse>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  initialLoad: boolean
  setInitialLoad: Dispatch<SetStateAction<boolean>>
  newInstall: boolean
  setNewInstall: Dispatch<SetStateAction<boolean>>
  tinHolds: any
  setTinHolds: any
  detailsTab: string
  setDetailsTab: Dispatch<SetStateAction<string>>
  carriers: ClaimCarrierData[]
  setCarriers: Dispatch<SetStateAction<ClaimCarrierData[]>>
  attachmentCarriers: AttachmentCarrier[]
  setAttachmentCarriers: Dispatch<SetStateAction<AttachmentCarrier[]>>
  state: ClaimGridState
  setState: Dispatch<SetStateAction<ClaimGridState>>
  claim: ClaimDetailsModelExtended
  setClaim: Dispatch<SetStateAction<ClaimDetailsModelExtended>>
  claimId: any //TODO: Update to the correct type and move parsing to happen when clicking on a grid row
  setClaimId: Dispatch<SetStateAction<any>>
  dateRange: any
  setDateRange: any
  ignoreClaim: boolean
  setIgnoreClaim: Dispatch<SetStateAction<boolean>>
  filterState: any
  setFilterState: any
  filterLoading: boolean
  queuedStatusSummary$: Observable<QueuedStatusSummary>
  claimStatusCodes$: Observable<ClaimStatusCodes[]>

  // functions
  getClaims: any
  detailsFilterChange: any
  claimRequest: any
  handleTransmitDate: any
  backToAllClaims: any
  getAttachmentCarrierList: () => Promise<void>

  // columns
  defaultColumns: any
  storageColumns: any

  // authentication
  authentication: any
  authenticationLoaded: any

  isPmg: any
}

type QueuedStatusSummaryItem = {
  Count: number
  Status: string
}

export type QueuedStatusSummary = {
  summary: QueuedStatusSummaryItem[]
  total: number
}

export const ClaimGridContext = createContext<ClaimGridContextType>(null)

const store = window.localStorage
const dateFormat = 'MM/dd/yyyy h:mm a'

export const ClaimGridContextProvider = observer(({ children }: any) => {
  const ClaimState$ = useObservable({
    showClaimDetail: false,
    isNewClaim: false,
    forceShowAttachmentTab: false,
    currentClaimIDToDelete: null,
  })

  const ClaimSettings$ = useObservable<ClaimSettingsResponse>()

  const flags = LDFlags$.get()
  const authentication = GlobalState.Auth.peek()

  const queuedStatusSummary$ = useObservable<QueuedStatusSummary>()
  const claimStatusCodes$ = useObservable<ClaimStatusCodes[]>()

  const getDefaultDetailTab = () => {
    return flags?.activityLog ? 'Patient' : 'Status'
  }

  const authenticationLoaded = true
  const storageDays = store.getItem('historySetting')
  const initialDays = storageDays ? parseInt(storageDays) : 15
  const storageColumns = JSON.parse(
    store.getItem('claim-default-columns'),
  ) as string[]
  const defaultColumns = [
    'Details',
    'TransmitDate',
    'PatientName',
    'SubscriberName',
    'Carrier',
    'Status',
    'StatusDescription',
  ]
  const [loading, setLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [newInstall, setNewInstall] = useState(false)
  const [tinHolds, setTinHolds] = useState([])
  const [detailsTab, setDetailsTab] = useState(getDefaultDetailTab)
  const [carriers, setCarriers] = useState<any>([])
  const [attachmentCarriers, setAttachmentCarriers] =
    useState<AttachmentCarrier[]>()
  const [claimId, setClaimId] = useState()
  const [claim, setClaim] = useState<ClaimDetailsModelExtended>()
  const [ignoreClaim, setIgnoreClaim] = useState(false)

  const [state, setState] = useState<ClaimGridState>({
    Key: 0,
    Data: [],
    Total: 0,
    Filters: {
      CurrentPage: 1,
      PageSize: 50,
      SortColumn: 'TransmitDate',
      SortDirection: 'desc',
      Search: '',
      Refetch: true,
      Queued: null,
      Ignored: false,
      Config: {},
      Options: {},
    },
    SelectAll: false,
    SelectedIds: [],
    SelectedGridKeys: [],
    ActiveTab: '',
    VisibleColumns: storageColumns || defaultColumns,
    Install: {
      Isolate: false,
      SerialId: GlobalState.SerialId.get(),
      SerialNickname: '',
    },
  })

  const [filterState, setFilterState] = useState({})

  const [dateRange, setDateRange] = useState<{
    dates: [Date, Date]
    numberOfDays: number
  }>({
    dates: [
      startOfDay(sub(new Date(), { days: initialDays })),
      endOfDay(new Date()),
    ],
    numberOfDays: initialDays,
  })
  const isPmg: boolean =
    authentication.CustomerTypeId !== CustomerTypes.Standard

  const getClaims = async () => {
    setLoading(true)
    const copy = { ...state }
    let columns = copy.VisibleColumns

    const serialId = state.Install.SerialId

    if (!isPmg) {
      checkTinHold()
    }

    if (initialLoad && serialId) {
      const settings = await api.getInstallSettings(serialId)

      if (!settings.data.Serial_Nickname) setNewInstall(true)

      if (settings.data.Isolate)
        columns = columns.filter((c: any) => c !== 'Install')

      copy.Install.Isolate = settings.data.Isolate
      copy.Install.SerialNickname = settings.data.Serial_Nickname
    }

    handleTransmitDate(copy)

    const statusCodes: ClaimStatusCodes[] = await getValidationCodes()

    const request = claimRequest(false, copy.Install)
    const response = await ClaimsApiFactory().GetClaims(request)

    const filterResponse = null

    if (initialLoad) {
      store.removeItem('grid-filter-select-all')
      setInitialLoad(false)
      const hasUnsentClaims =
        response.data == null ? false : response.data.HasUnsentClaims ?? false
      if (hasUnsentClaims) copy.ActiveTab = 'Unsent'
      else copy.ActiveTab = 'Sent'
    }

    // Temporary logic to ensure 'Actions' column doesn't show if validation flag is not present
    // Also have to prevent it from showing on the 'Sent' tab
    if (flags.claimValidation && copy.ActiveTab === 'Unsent') {
      !columns.includes('Actions') && columns.push('Actions')
    } else columns = columns.filter((column) => column !== 'Actions')

    if (response?.data) {
      if (flags.claimValidation) {
        copy.ActiveTab === 'Unsent' &&
          queuedStatusSummary$.set({
            summary: response.data
              .QueuedStatusSummary as QueuedStatusSummaryItem[],
            total: (response.data.HasUnsentClaims
              ? response.data.TotalClaims
              : 0) as number,
          })
      }

      const data = response.data
      copy.Data = data.Claims || []
      copy.Total = data.FilteredClaims || 0
      copy.VisibleColumns = columns

      copy.Data = handleClaimStatusMapping(copy.Data, statusCodes)

      if (store.getItem('grid-filter-select-all')) {
        const selected = copy.Data.filter(
          (d: any) => !copy.SelectedIds.includes(d.ClaimID),
        )
        copy.SelectedGridKeys = selected.map((u: any) => {
          return u.ClaimID
        })
      }

      if (copy.Filters.Refetch) {
        if (
          filterResponse &&
          filterResponse.data &&
          filterResponse.data.FilterDropdowns
        ) {
          Object.keys(filterResponse.data.FilterDropdowns).map((k) => {
            const values = filterResponse.data.FilterDropdowns[k]?.filter(
              (d: any) => d && d !== '',
            )
            filterResponse.data.FilterDropdowns[k] = values?.map(
              (v: any, i: number) => ({ key: i, value: v }),
            )
          })
        }

        copy.Filters.Options = filterResponse?.data?.FilterDropdowns || {}
      }
    }
    const refetch = copy.Filters.Refetch
    copy.Filters.Refetch = true

    setState(copy)
    setLoading(false)

    if (refetch) {
      setFilterLoading(true)
      GetDropdownFilters(copy)
    }
  }

  const getValidationCodes = async () => {
    if (flags.claimValidation) {
      try {
        //todo: use react query so this auto updates every X minutes
        const statusCodesResponse = await GetClaimStatusCodes()

        if (statusCodesResponse) {
          const statusCodes = statusCodesResponse.data
          claimStatusCodes$.set(statusCodes)
          return statusCodes
        }
      } catch (err) {
        LogError(err, 'Failed to get claim validation codes')
      }
    }
    return null
  }

  //TODO: Determine if this call is actually needed an get rid of it if not
  const GetDropdownFilters = async (copy: any) => {
    setFilterState({})
    const response = await ClaimsApiFactory().GetFilterDropdowns(
      claimRequest(false, copy.Install),
    )

    try {
      await handleFilterDropDowns(response)
      setFilterLoading(false)
    } catch (error) {
      LogError(error, 'Failed to get claim dropdown filters')
      setFilterLoading(false)
    }
  }

  const handleFilterDropDowns = async (filterResponse: any) => {
    const {
      data: { FilterDropdowns },
    } = filterResponse

    if (filterResponse && filterResponse.data && FilterDropdowns) {
      Object.keys(FilterDropdowns).map((k) => {
        const values = FilterDropdowns[k]?.filter((d: any) => d && d !== '')
        FilterDropdowns[k] = values?.map((v: any, i: number) => ({
          key: i,
          value: v,
        }))
      })
      setFilterState(FilterDropdowns)
    }
  }

  const handleClaimStatusMapping = (
    claimsData: ClaimModel[],
    statusCodes?: ClaimStatusCodes[],
  ) => {
    const claimsCopy = claimsData

    claimsCopy.forEach((claimsItem: ClaimModelExtended) => {
      if (claimsItem.Status) matchClaimStatus(claimsItem)
      matchClaimStatusDescription(claimsItem)

      // Mapping validation status codes for each claim
      if (statusCodes) {
        const validationResults = JSON.parse(claimsItem.ValidationResults)

        claimsItem.MappedClaimStatusCodes = mapStatusCodeDetails(
          validationResults,
          claimStatusCodes$.peek(),
        )
      }
    })

    return claimsCopy
  }

  const checkTinHold = async () => {
    const { data } = await api.checkTinHold({
      Username: GlobalState.UserInfo.userName.peek(),
    })
    if (data.length > 0) {
      setTinHolds(data)
    }
  }

  const detailsFilterChange = (property: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    copy.Filters.Config[property] = true
    setState(copy)
  }

  const claimRequest = (
    sendAll: boolean = undefined,
    install: any = state.Install,
    rowActionClaimId?: string[],
  ) => {
    let processAll = false
    const filters = state.Filters

    let selectedClaimIDs = state.SelectedIds
    if (sendAll) {
      processAll = sendAll
      if (flags.claimValidation) {
        selectedClaimIDs = state.Data.map((claim) => {
          if (
            state.SelectedIds.includes(claim.ClaimID) &&
            claim.Status.toLowerCase() !== 'pending'
          ) {
            return claim.ClaimID
          }
        })
      }
    } else {
      const selectAll = store.getItem('grid-filter-select-all')
      processAll = selectAll === 'true'
    }

    return {
      Queued: filters.Queued,
      Hidden: filters.Ignored,
      Range: filters.PageSize,
      Offset: filters.PageSize * (filters.CurrentPage - 1),
      SortColumn: filters.SortColumn,
      SortDirection: filters.SortDirection,
      Filters: filters.Config,
      SearchText: filters.Search,
      ProcessAll: processAll,
      ClaimList: rowActionClaimId || selectedClaimIDs,
      Serial: install.Isolate ? install.SerialId : '',
    }
  }

  const handleTransmitDate = (copy: any) => {
    if (copy.ActiveTab === 'Sent' || RoleHelper$.Claims.HideGridTabs.peek()) {
      copy.Filters.Config.TransmitDate = `${format(
        startOfDay(new Date(dateRange.dates[0])),
        dateFormat,
      )}, ${format(endOfDay(new Date(dateRange.dates[1])), dateFormat)}`
    } else delete copy.Filters.Config.TransmitDate
  }

  const backToAllClaims = () => {
    setClaim(undefined)
    setClaimId(undefined)
    ClaimState$.set({
      showClaimDetail: false,
      isNewClaim: false,
      forceShowAttachmentTab: false,
      currentClaimIDToDelete: null,
    })
    setDetailsTab(flags?.activityLog ? 'Patient' : 'Status')
  }

  const getAttachmentCarrierList = async () => {
    if (attachmentCarriers == null || attachmentCarriers == undefined) {
      await GetCarrierList().then((response) => {
        setAttachmentCarriers(response.data.attachmentCarriers)
      })
    }
  }

  return (
    <ClaimGridContext.Provider
      value={{
        // legendstate
        ClaimState$,
        ClaimSettings$,
        queuedStatusSummary$,
        claimStatusCodes$,
        // state,
        loading,
        setLoading,
        initialLoad,
        setInitialLoad,
        newInstall,
        setNewInstall,
        tinHolds,
        setTinHolds,
        detailsTab,
        setDetailsTab,
        carriers,
        setCarriers,
        attachmentCarriers,
        setAttachmentCarriers,
        state,
        setState,
        claim,
        setClaim,
        claimId,
        setClaimId,
        dateRange,
        setDateRange,
        ignoreClaim,
        setIgnoreClaim,
        filterState,
        setFilterState,
        filterLoading,

        // functions
        getClaims,
        detailsFilterChange,
        claimRequest,
        handleTransmitDate,
        backToAllClaims,
        getAttachmentCarrierList,

        // columns
        defaultColumns,
        storageColumns,

        // authentication
        authentication,
        authenticationLoaded,

        isPmg,
      }}
    >
      {children}
    </ClaimGridContext.Provider>
  )
})

export const useClaimGridContext = () => {
  const context = useContext(ClaimGridContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
