import { observable } from '@legendapp/state'
import { enableReactComponents } from '@legendapp/state/config/enableReactComponents'
import { enableReactTracking } from '@legendapp/state/config/enableReactTracking'
import { ObservablePersistLocalStorage } from '@legendapp/state/persist-plugins/local-storage'
import { configureObservableSync, syncObservable } from '@legendapp/state/sync'

import { ActiveServicesObject, TrellisLdFlags } from 'utils'

import { CustomerUserModel } from 'trellis:api/account/account-client'
import { ClaimSettingsResponse } from 'trellis:api/claim/claim-client'
import { EligbilityStatusMapping } from 'trellis:api/eligibility/eligibility-client'
import {
  BillingDetailsResponse,
  GetConstantsResponse,
  PracticeInfo,
} from 'trellis:api/practice/practice-client'
import { StatementsGetFacilityResponse } from 'trellis:api/statements/statements-client'
import {
  IAuthenticatedApiModel,
  LegalBusinessStatusResponse,
} from 'trellis:utilities/api'

export type GlobalStateType = {
  AuthLoading: boolean
  /**
   * Used to determine if a user is logged in, setting this to false will trigger logout logic
   */
  IsAuthenticated: boolean
  Auth?: IAuthenticatedApiModel
  AttachmentWrapperAccessToken?: string | null
  /** Needed for LD, comma delimited list of active services */
  ActiveServicesString?: string
  ActiveServices?: ActiveServicesObject
  DecodedJwt?: TrellisJWT
  PracticeInfo?: PracticeInfo
  UserInfo?: CustomerUserModel
  LegalBusinessStatus?: LegalBusinessStatusResponse
  HasPmsData?: boolean
  ClaimSettings?: ClaimSettingsResponse //TODO: move this into the claims object and update ref
  BillingDetails?: BillingDetailsResponse
  Constants?: GetConstantsResponse
  PaymentsStatus?: StatementsGetFacilityResponse
  EligibilityStatusMapping?: EligbilityStatusMapping[]

  /**
   * Serial id coming in from RL
   */
  SerialId?: string //TODO: move this into the claims object and update ref

  /**
   * Claim related persitant settings
   */
  Claims?: ClaimSessionSettings
}

export type ClaimSessionSettings = {
  /**
   * Claim isolate setting coming in from RL, not currently used
   */
  ClaimIsolate?: boolean
}

export type SaveBillingRequest = {
  paymentType: string
  token: string
  billToName: string
  creditCardType: string
  creditCardExpireYear: number
  creditCardExpireMonth: number
  lastFour: string
}

export type SaveBillingResponse = {
  email: string
  facilityId: string
  netSuiteIntegrationApi: string
  netSuiteClientId: string
  netSuiteClientSecret: string
  netSuiteClientVersion: string
  paymentType: string
  cardType: string
  lastFour: string
  expDate: string
  error: boolean
  customerId: number
}

export type TrellisJWT = {
  active_services: string
  aud: string
  authentication_token: string
  customer_id: string
  customer_name: string
  customer_type: string
  email: string
  exp: number
  facility_id: string
  iat: number
  is_pmg_master: string
  iss: string
  jti: string
  mason_client_id: string
  mason_client_location_id: string
  nbf: number
  opera_company_id: string
  opera_customer_id: string
  pmg_group_id: string
  pmg_group_region_id: string
  registration_token: string
  token_type: string
  user_first_name: string
  user_id: number
  user_last_name: string
}

export type AppThemeType =
  | 'dental-intel-theme'
  | 'rpractice-theme'
  | 'trellis-theme'
export const appTheme$ = observable<AppThemeType>('trellis-theme')

export const GlobalState = observable<GlobalStateType>()

export const LDFlags$ = observable<TrellisLdFlags>()

export const logoutError = observable<string>()

enableReactComponents()

// https://legendapp.com/open-source/state/v3/usage/configuring/
enableReactTracking({
  warnUnobserved: true, //this only happens when process.env.NODE_ENV === 'development'
  auto: true,
})

// Setup global sync and persist configuration. These can be overriden
// per observable.
// https://legendapp.com/open-source/state/v3/sync/persist-sync/#local-storage-react
configureObservableSync({
  persist: {
    plugin: ObservablePersistLocalStorage,
  },
})

syncObservable(GlobalState, {
  persist: {
    name: 'trellisGlobalState',
  },
})

export default GlobalState
