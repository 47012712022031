import {
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InsertRowRightOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Col, Drawer, Input, Popover, Row, Tabs, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import { Labels } from 'trellis:constants/labels'

import { useClaimArchiveContext } from '../context/ClaimArchiveContext'
import { ClaimArchiveColumnSelector } from './ClaimArchiveColumnSelector'

import '../../../components/claims/controls/toolbar/toolbar.scss'

const { Search } = Input
const { TabPane } = Tabs

export const ClaimArchiveToolbar = () => {
  const { state, toggleIgnore, print, getDownloadRows, searchTable } =
    useClaimArchiveContext()
  const [search, setSearch] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)
  const [showingSettings, setShowingSettings] = useState(false)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const onSearchChange = (e: any) => {
    setInitialLoad(false)
    setSearch(e.target.value || '')
  }

  const viewIgnored = state.Ignored

  return (
    <Row
      className='toolbar-bottom-border'
      justify='end'
      align='middle'
    >
      <Col flex={1}>
        <Tabs>
          <TabPane
            key='Archive'
            tab='Archive'
          />
        </Tabs>
      </Col>
      <Col className='pr-100'>
        <Popover
          trigger='click'
          title={<b className='fs-150'>Can't find your claims?</b>}
          content={
            <>
              <ul>
                <li>
                  Claims sent from Remote Lite Version 5 or earlier will not
                  appear in your search
                </li>
                <li>
                  Make sure to enter exact search terms (e.g. 'John' instead of
                  'Jo')
                </li>
              </ul>
            </>
          }
        >
          <a
            href='#'
            className='cant-find-claims'
          >
            Can't find your claims?
          </a>
        </Popover>
      </Col>
      <Col className='claim-history-search'>
        <Search
          placeholder='Search Patient, Subscriber, Carrier, etc.'
          onChange={onSearchChange}
          value={search}
          allowClear
        />
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title={Labels.viewHidden}
          mouseEnterDelay={0.5}
          className='mt-025'
        >
          {viewIgnored && (
            <EyeInvisibleOutlined
              onClick={toggleIgnore}
              className='grid-control-icon'
            />
          )}
          {!viewIgnored && (
            <EyeOutlined
              onClick={toggleIgnore}
              className='grid-control-icon'
            />
          )}
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Print'
          mouseEnterDelay={0.5}
        >
          <PrinterOutlined
            className='grid-control-icon'
            onClick={() => print()}
          />
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Download'
          mouseEnterDelay={0.5}
        >
          <CSVLink
            filename='Claims.csv'
            data={getDownloadRows(state.Data)}
          >
            <DownloadOutlined className='grid-control-icon' />
          </CSVLink>
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Columns'
          mouseEnterDelay={0.5}
        >
          <InsertRowRightOutlined
            onClick={() => setShowingSettings(true)}
            className='grid-control-icon'
          />
        </Tooltip>
        <Drawer
          title='Select | Reorder Columns'
          placement='right'
          width={300}
          onClose={() => setShowingSettings(false)}
          open={showingSettings}
        >
          <ClaimArchiveColumnSelector
            setShowingSelection={setShowingSettings}
          />
        </Drawer>
      </Col>
    </Row>
  )
}
